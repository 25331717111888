import React from "react"
import styled from "styled-components"
import PageTemplate from "../components/pageTemplate"
import { Row, Col } from "reactstrap"
import drjoerg from "../assets/images/dr_joerg.jpg"

let UL = styled.ul`
  > li {
    font-family: "Roboto", sans-serif;
  }
`

let Ueber = () => (
  <PageTemplate title="Über mich">
    <Row className="d-flex align-items-start">
      <Col md="8">
        <div className="mb-4">
          <h2>Dr. Astrid Jörg-Koutromanos</h2>
          <br />
          <UL>
            <li>
              Studium der Humanmedizin an der Karl Franzens Universität Graz
            </li>
            <li>Promotion 1997</li>
            <li>1997- 2001 Ausbildung zur Ärztin für Allgemeinmedizin</li>
            <li>Jus Practicandi 8/2001</li>
            <li>
              seitdem als Allgemeinmedizinerin tätig (Praxisvertretungen in
              Stmk, Ktn, Bgl)
            </li>
            <li>02/2008 bis 10/2009 Wahlärztin</li>
          </UL>
          <UL>
            <li>
              Zusatzausbildungen:
              <ul>
                <li>ÖÄK Dipl. f. Akupunktur und Notfallsmedizin (Notarzt)</li>
                <li>Lehrgang für Telemedizin an der FH Joanneum</li>
                <li>
                  laufende Ausbildung: TCM (traditionell chinesische Medizin)
                </li>
              </ul>
            </li>
          </UL>
        </div>
      </Col>
      <Col md="4">
        <img src={drjoerg} alt="Dr. Jörg-Koutromanos" />
      </Col>
    </Row>
    <p>
      <strong>
        Seit Nov. 2009: KASSENORDINATION FÜR ALLGEMEINMEDIZIN IN ST. MAREIN/GRAZ
      </strong>
    </p>
  </PageTemplate>
)

export default Ueber
